.logreader__container--logpanel--settings{
  position: fixed;
  background-color: #ffffff;
  color:black;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  box-shadow: 7px 7px 7px 0px rgb(0 0 0 / 62%);
  /* transition-duration: .7s; */
  animation: example .3s linear;
  opacity: 0;
  width: 50%;
}
.logreader__container--logpanel--settings.active{
  z-index: 1000;
  opacity: 1;
}
.logpanel__list--params{
  width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  background-color: #dbdbdb;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 10px;
}
.logpanel__item--params{
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.logpanel__label--params{
  margin: 0;
    margin-right: 5px;
}
.logpanel__button--delete{
  padding: 0px;
  height: 30px;
  width: 30px;
}
.logpanel__list--params::-webkit-scrollbar{
  width: 3px;
  background-color: #6e6e6e;
} 
.logpanel--settings__param__container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logpanel--settings__param__container select,
.logpanel--settings__param__container input{
  height: 30px;
  width: 50%;
  text-align: center;
}
.logpanel--settings__params__container{
  width: 95%;
  margin-right: 20px;
}
.logpanel__button--add{
  /* height: 100%; */
  margin-top: 16px;
}
.logpanel--settings__wrapper{
  display: flex;
}
.logpanel__button--add--chart{
  width: 100%;
  height: 35px;
  background-color: #dadada;
}
@keyframes example {
  from {opacity: 0;}
  to {opacity: 1;}
}