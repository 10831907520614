.spraying{
    
}
.mapboxgl-marker.takeoff{
    top: 6px;
}
.mapbox_gl_active_indicator{
    width: 130%;
    height: 130%;
    position: absolute;
    top: -9px;
    left: -5px;
    border: 0px solid white;
    border-radius: 23px;
}
.marker_active_indicator--active{
    border: 3px solid white;
}