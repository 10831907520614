.centered{
	width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgb(0 0 0 / 82%);
  z-index: 10;
    /* filter: blur(6px) contrast(20); */
}
.loading__message{
  color: #ffffff;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 300;
}
.blob-1,.blob-2{
	width:50px;
	height:50px;
	position:absolute;
	background:wheat;
	border-radius:50%;
	top:50%;left:50%;
	transform:translate(-50%,-50%);
}
.blob-1{
	left:40%;
	animation:osc-l 2.5s ease infinite;
}
.blob-2{
	left:60%;
	animation:osc-r 2.5s ease infinite;
	background:#5a5a5a;
}
@keyframes osc-l{
	0%{left:40%;}
	50%{left:50%;}
	100%{left:40%;}
}
@keyframes osc-r{
	0%{left:60%;}
	50%{left:50%;}
	100%{left:60%;}
}