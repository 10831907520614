.default_cmp{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .window{
        width: 60%;
        background-color: grey;
        color: whitesmoke;
        padding: 10px 20px;
        border-radius: 10px;
        p{
            margin: 0;
        }
        .title{
            font-size: 25px;
        }
        .text{
            color: wheat;
            font-size: 20px;
        }
        .pl_list{
            padding: 0;
            margin: 0;
            li{
                font-size: 20px;
                color: wheat;
            }
        }
    }
}