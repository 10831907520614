.mapbox_gl_num{
  position: absolute;
  top: 2px;
  left: 50%;
  color: white;
  font-weight: bold;
  font-size: 11px;
  transform: translateX(-50%);
}
.mapbox_gl_active_indicator{
  width: 130%;
  height: 130%;
  position: absolute;
  top: -9px;
  left: -5px;
  border: 0px solid white;
  border-radius: 23px;
}
.mapboxgl-marker.waypoint{
  top:-3px
}
.mapbox_gl_indicator_number{
  position: absolute;
  top: 3px;
  left: 0;
  color: #ffffff;
  font-size: 12px;
  transform: translateX(-50%);
  left: 50%;
}
.marker_active_indicator--active{
  border: 3px solid white;
  animation: marker_active_indicator_activate .3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
@keyframes marker_active_indicator_activate {
  from {
    border: 0px solid white;
  }
  to {
    border: 3px solid white;
  }
}