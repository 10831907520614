@import "../mixin.scss";

.settings_spray{
    position: fixed;
    top: 75px;
    right: 0;
    width: 25%;
    height: calc(100vh - 75px);
    background-color: #1E1E1Ebb;
    border-radius: 6px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        background-color: #808080;
        border-radius: 6px 0 0 0;
        border-bottom: 2px solid whitesmoke;
        @include flexContainerCenterWrap();
        p{
            @include text(30, 10);
            margin: 0
        }
    }
    .navigation{
        height: 40px;
        width: 100%;
        @include flexContainerCenterWrap();
        justify-content: space-around;
        button{
            position: relative;
            padding: 0;
            width: 48%;
            height: 100%;
            border: none;
            border-radius: 0 0 6px 6px;
            margin: 0;
            background-color: #808080;
            @include flexContainerCenterWrap();
            transition-duration: .4s;
            &:hover{
                background-color: rgb(75, 75, 75);
            }
            img{
                position: absolute;
                height: 70%;
                &.left{left: 5px}
                &.right{right: 5px;}
            }
            p{
                width: 70%;
                @include text(20, 8);
                margin: 0;
            }
        }
    }

    .main_spray{
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .file_name{
            width: 90%;
            height: 60px;
            @include flexContainerCenterWrap();
            background-color: #1E1E1E;
            border-radius: 6px;
            input{
                width: 90%;
                height: 40px;
                text-align: center;
                @include text(20, 10);
                color: #1E1E1E;
                border: none;
                border-radius: 6px;
                &:focus{outline: none;}
            }
        }

        .block{
            width: 90%;
            height: calc(100% - 170px);
            background-color: #1E1E1E;
            margin: 0;
            padding: 10px 0;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        .save{
            margin-top: 10px;
            width: 100%;
            @include flexContainerCenterWrap();
            button{
                margin: 0;
                width: 90%;
                height: 50px;
                background-color: #808080;
                text-align: center;
                border: none;
                border-radius: 6px;
                transition-duration: .4s;
                @include flexContainerCenterWrap();
                @include bText(30, 10);
                &:hover{
                    background-color: rgb(75, 75, 75);;
                }
            }
        }
    }
}