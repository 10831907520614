.container--weather{
  margin: 3px 0;
  /* border-right: 0.1em grey solid;
  border-left: 0.1em grey solid; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.header__item--custom::before{
  content: "";
  top: 50%;
  left: 0px;
  width: 4px;
  height: 48px;
  position: absolute;
  background-color: white;
  transform: translateY(-50%);
  border-radius: 20px;
}
.header__item--custom:nth-last-child(1)::after{
  content: "";
  top: 50%;
  right: -20px;
  width: 4px;
  height: 48px;
  position: absolute;
  background-color: white;
  transform: translateY(-50%);
  border-radius: 20px;
}


.container--inner--weather{
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items:flex-start;
  margin: 0 3px;
}
.icon__container--weather img{
  width: 24px;
}
.text--weather{
  line-height: 27px;
  font-weight: 700;
}
.hidden--header{
  position: absolute;
  background-color: grey;
  border-radius: 0.5em;
  padding: 2px 5px;
  opacity: 0;
  transition-duration: 0.7s;
}

.column--weather{
  margin-left: 15px;
}

.column--weather:hover{
  cursor: default;
}
.column--weather:hover .hidden--header{
  opacity: 1;
}